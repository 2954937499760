import { limitOptions } from '../getLimitOptions';

describe('getLimitOptions', () => {
  it('should return limitOptions', () => {
    expect(limitOptions()).toEqual([
      { text: 'Last 7 days', value: '7' },
      { text: 'Last 15 days', value: '15' },
      { text: 'Last 30 days', value: '30' },
      { text: 'Last week', value: 'week' },
      { text: 'Last month', value: 'month' },
    ]);
  });
});
