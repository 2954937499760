import { transferIntervals } from '../getTransferIntervals';
import { __ } from '../../../../i18n';

describe('getTransferIntervals', () => {
  it('should return transferIntervals', () => {
    expect(transferIntervals()).toEqual([
      {
        value: 'DAILY',
        text: __('Daily'),
        key: 0,
      },
      {
        value: 'WEEKLY',
        text: __('Weekly'),
        key: 1,
      },
      {
        value: 'MONTHLY',
        text: __('Monthly'),
        key: 2,
      },
      {
        value: 'MANUAL',
        text: __('Manual'),
        key: 3,
      },
    ]);
  });
});
