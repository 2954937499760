import { weekdays } from '../getWeekDays';

describe('getWeekDays', () => {
  it('should return weekdays', () => {
    expect(weekdays()).toEqual([
      { value: 1, text: 'Monday' },
      { value: 2, text: 'Tuesday' },
      { value: 3, text: 'Wednesday' },
      { value: 4, text: 'Thursday' },
      { value: 5, text: 'Friday' },
    ]);
  });
});
