import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { Button } from 'semantic-ui-react';

import Modal from '../../components/Modal';
import Controller from '../../components/Controller';

import { __ } from '../../i18n';
import * as utils from '../../utils';

@inject('store')
@graphql(gql`mutation createOrganizationWithdrawal($createOrganizationWithdrawal: CreateOrganizationWithdrawalInput!) {
    createOrganizationWithdrawal(input:$createOrganizationWithdrawal) {
      organizationWithdrawal {
        amount
      }
    }
  }`, {
  options: {
  refetchQueries: ['EntityBankAccountsQuery', 'AccountEditQuery']
  }
  })
@observer
export default class AccountWithdrawal extends Controller {
  request = () => {
    const { currentEntity, currentOrganization } = this.props.store;
    const { account } = this.props;
    const balance = account.balance.available;

    this.setState({ loading: true });

    this.props.mutate({
      variables: {
        createOrganizationWithdrawal: {
          organizationId: currentOrganization.id,
          organizationAccountId: account.id,
          amount: balance,
          entityId: currentEntity.id

        }
      }
    }).then(() => {
      this.setState({ loading: false });
      this.props.store.snackbar = { active: true, message: __('Successful withdrawal'), success: true };
      this.props.store.appends.pop();
    }).catch((error) => {
      this.setState({ loading: false });

      if (error.message === 'Network error: Failed to fetch') {
        this.props.store.snackbar = { active: true, message: __('Can\'t connect to our service, please check your network connection and try again'), success: false, dismissAfter: 6000 };
      } else {
        this.props.store.snackbar = { active: true, message: utils.handleError(error.graphQLErrors[0]), success: false };
      }
    });
  }

  render() {
    const { account } = this.props;
    const balance = account.balance.available.toLocaleString('pt-BR', { style: 'currency', currency: (account.currency || 'BRL') });
    const content =
    __('<div style="margin-bottom:10px;"><span style="color:#0069FF; font-weight: bold">' +
        '%1$s</span> will be deposited to the <span style="color:#0069FF; font-weight: bold">%2$s</span> account registered under the name %3$s</div>' +
        '<div>' +
        '<div style="margin-bottom:5px;">&#8226; Bank accounts that aren\'t from Bradesco pay a <span style="color:#0069FF; font-weight: bold;">R$3,67</span> fee during transfer</div>' +
        '<div style="margin-bottom:5px;">&#8226; Withdrawals requested after 3 p.m in working days will be proccessed in the next working day</div>' +
        '<div style="margin-bottom:5px;">&#8226; It takes a few minutes for the transfer to be shown in the statement</div></div>', balance, this.props.bankDescription.value, account.legalName);
    const htmlObject = <div dangerouslySetInnerHTML={{ __html: content }} />;
    const cancelButton = (
      <Button
        floated="left"
        basic
        content={__('Cancel')}
        onClick={() => this.props.store.appends.pop()}
      />);

    const submitButton = (
      <Button
        primary
        content={__('Confirm')}
        onClick={this.request}
        disabled={this.state.loading}
        loading={this.state.loading}
      />);
    return (
      <Modal
        id={account.id}
        header={__('Withdrawal')}
        content={htmlObject}
        actions={[cancelButton, submitButton]}
      />
    );
  }
}
