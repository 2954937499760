import { getPaymentMethod } from '../getPaymentMethod';

describe('getPaymentMethod', () => {
  it('should return the the correct value based on the input', () => {
    let returnedValue = getPaymentMethod('WITHDRAWAL', 'BOLETO');
    expect(returnedValue).toEqual('-');
    returnedValue = getPaymentMethod('WITHDRAWAL', 'PIX');
    expect(returnedValue).toEqual('-');
    returnedValue = getPaymentMethod('WITHDRAWAL', 'CARD');
    expect(returnedValue).toEqual('-');
  });
});
