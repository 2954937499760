import { inject } from 'mobx-react';
import React, { Component } from 'react';

import BetaModal from '../../components/ui/Modal';
import Button from '../../components/ui/Button';
import { __ } from '../../i18n';

/**
 * @typedef {{
 *  confirmationAction: () => any;
 *  accountData: {
 *   bankName: string;
 *   agency: string;
 *   currentAccount: string;
 *   taxId: string;
 *  };
 * }} Props
 * @extends {Component<Props>}
 */
@inject('store')
export default class BankConfirmationModal extends Component {
  render() {
    const { confirmationAction, accountData: { bankName, agency, currentAccount, taxId } } = this.props;

    return (
      <BetaModal
        id="BankConfirmationModal"
        header={__('Confirm bank details')}
        size="tiny"
        scrolling
        onClose={() => this.props.store.appends.pop()}
        content={
          <div>
            <p style={{ marginBottom: 12 }}>{__('Check that all the information is correct:')}</p>
            <p><span>{__('Bank')}:</span> {bankName}</p>
            <p><span>{__('Bank agency (with digit)')}:</span> {agency}</p>
            <p><span>{__('Current account')}:</span> {currentAccount}</p>
            <p><span>{__('Tax ID of bank account')}:</span> {taxId}</p>

            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 32 }}>
              <Button
                transparent
                round
                text={__('Cancel')}
                onClick={() => this.props.store.appends.pop()}
              />
              <Button
                round
                text={__('Confirm')}
                onClick={() => { confirmationAction(); this.props.store.appends.pop(); }}
              />
            </div>
          </div>
        }
      />
    );
  }
}
