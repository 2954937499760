import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import AccountForm from '../Account/Form';

import Controller from '../../components/Controller';
import Modal from '../../components/Modal';

import { __ } from '../../i18n';
import * as utils from '../../utils';

@inject('store')
@graphql(gql`query EntityAddAccountQuery ($organizationId: ID!) {
  node(id: $organizationId) @connection(key: "Organization", filter: ["organizationId"]) {
    ... on Organization {
      id: dbId
      accounts {
        nodes {
          id: dbId
          bankName
        }
      }
    }
  }
}
`, {
  options: ownProps => ({
    variables: {
    organizationId: ownProps.params.organization_id
    }
    })
  })
@graphql(gql`mutation createOrganizationAccount($createOrganizationAccountMutation: CreateOrganizationAccountInput!) {
  createOrganizationAccount(input: $createOrganizationAccountMutation) {
    organizationAccount {
      dbId
    }
  }
}`, {
  options: {
  refetchQueries: ['EntityBankAccountsQuery', 'EntityAddAccountQuery']
  }
  })
@observer
export default class AccountAdd extends Controller {
  treatValues = (values) => {
    values.legalName = values && values.legalName && values.legalName.trim();
    values.bankName = values && values.bankName && values.bankName.trim();
    values.organizationId = this.props.data.node.id;
    values.organizationAccountId = values && values.bankName && values.bankName.substring(0, 3);
    values.agency = values && values.agency && values.agency.trim();
    values.currentAccount = values && values.currentAccount && values.currentAccount.trim();
    values.taxId = values && values.taxId && values.taxId.trim();
    values.phone = values && values.phone && values.phone.replace(/[^0-9]+/g, '');
    values.email = values && values.email && values.email.trim();

    if (values.transferInterval === 'MANUAL' || values.transferInterval === 'DAILY') {
      delete values.transferDay;
    }
    values.absorbedFee = [];
    if (values.absorbBoleto) {
      values.absorbedFee.push('BOLETO');
    }
    if (values.absorbCard) {
      values.absorbedFee.push('CARD');
    }
    if (values.absorbPix) {
      values.absorbedFee.push('PIX');
    }

    const bank = [values.organizationAccountId, values.agency, values.currentAccount];

    delete values.absorbBoleto;
    delete values.absorbCard;
    delete values.absorbPix;
    delete values.activeIndex;
    delete values.organizationAccountId;
    delete values.agency;
    delete values.currentAccount;
    delete values.errors;
    delete values.bankCode;

    return {
      ...values,
      bank: bank.join(','),
      currency: 'BRL',
      country: 'BR'
    };
  }

  request = (values) => {
    const { store, accounts } = this.props;
    this.setState({ loading: true });

    const mutationValues = this.treatValues(values);

    const props = {
      id: accounts && accounts.nodes && accounts.nodes.id,
      ...mutationValues
    };

    return this.props.mutate({
      variables: {
        createOrganizationAccountMutation: {
          ...props,
          entityId: store.currentEntity.id
        }
      }
    }).then(() => {
      this.setState({ loading: false });
      this.props.store.snackbar = { success: true };
    }).catch((err) => {
      this.props.store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false };
    });
  }


  render() {
    const { data, store } = this.props;

    if ((data.loading && !data.node) || !data.node) return <Modal loading />;

    const { nodes } = data && data.node && data.node.accounts;
    const organizationAccountId = nodes.length > 0 ? (nodes[0].id) : store.currentOrganization.id;
    return (
      <Controller
        id="AccountAdd"
        modal={{ size: 'small' }}
        add
        title={__('Register a new bank account')}
        form={AccountForm}
        onSubmit={this.onSubmit}
        onCancel={() => this.props.store.appends.pop()}
        loading={this.state.loading}
        formProps={{
          organizationAccountId
        }}
        {...this.props}
      />
    );
  }
}
