import { __ } from '../../../i18n';

export function weekdays() {
  return [
    {
      value: 1,
      text: __('Monday'),
    },
    {
      value: 2,
      text: __('Tuesday'),
    },
    {
      value: 3,
      text: __('Wednesday'),
    },
    {
      value: 4,
      text: __('Thursday'),
    },
    {
      value: 5,
      text: __('Friday'),
    },
  ];
}
