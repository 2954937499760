import { __ } from '../../../i18n';

export function getPaymentMethod(type, method) {
  if (type === 'WITHDRAWAL' || type === 'WITHDRAWAL_RETURN') return '-';

  if (method === 'BOLETO') return __('Boleto');
  if (method === 'PIX') return __('Pix');
  if (method === 'CARD') return __('Card');
  return method;
}
