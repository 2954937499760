import React from 'react';
import { inject, observer } from 'mobx-react';
import { Icon, Segment, Popup, Button, Image, Tab } from 'semantic-ui-react';
import InputMask from 'react-input-mask';
import pick from 'lodash/pick';

import PhoneInput from '../../components/PhoneInput';

import Form from '../../components/Form';
import ColoredCheckbox from '../../components/ColoredCheckbox';

import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { __ } from '../../i18n';
import { getAccountFormPaymentMethods, getBrazilianBanks } from '../../lib/payment';
import banks from '../../lib/banks';

import * as utils from '../../utils';
import StepsModal from '../../components/StepsModal';
import CepInput from '../../components/CepInput';
import BankConfirmationModal from './ConfirmationModal';

import { weekdays } from './helpers/getWeekDays';
import { transferIntervals } from './helpers/getTransferIntervals';

const styles = {
  stepName: {
    margin: 'auto'
  },
  label: {
    marginTop: '12px',
    marginBottom: '8px'
  },
  icon: {
    margin: 'auto',
    fontSize: '1em',
    color: 'white',
    fontWeight: 'bold',
  },
  nextIcon: {
    margin: 'auto',
    fontSize: '1em',
    color: '#495057',
    fontWeight: 'bold',
  },
  footer: {
    position: 'fixed',
    bottom: '0px',
    left: '50%',
    borderTop: '1px solid rgba(0, 0, 0, .1)',
    padding: '20px',
    display: 'flex',
    width: '100%',
    marginLeft: '-50%',
    backgroundColor: 'white',
    justifyContent: 'center'
  }
};

@inject('store')
@graphql(gql`query AccountFormQuery ($entityId: ID!) {
  organization: node(id: $entityId) @connection(key: "Entity", filter: ["entityId"]) {
    ... on Entity {
      id: dbId
      organization {
        id: dbId
        currency
        paymentMethodList {
          nodes {
            name
            splitConfiguration {
              nodes {
                installment
                fee
                feeType
              }
            }
          }
        }
      }
    }
  }
}
`, {
  options: ownProps => ({
    variables: {
    entityId: (ownProps.params && ownProps.params.entity_id) || ownProps.store.currentEntity.id,
    }
    })
  })
@observer
export default class AccountForm extends Form {
  // each position of this array is one step/tab
  defaultValues = [{
    bankName: '',
    legalName: '',
    agency: '',
    currentAccount: '',
    taxId: '',
    default: false,
    phone: '',
    email: '',
    errors: {},
    activeIndex: 0
  }, {
    holderName: '',
    zipCode: '',
    addressState: '',
    city: '',
    neighborhood: '',
    addressStreet: '',
    addressNumber: '',
    addressComplement: '',
  }, {
    absorbPix: false,
    absorbBoleto: false,
    absorbCard: false,
    transferInterval: 'MONTHLY',
    transferDay: 10,
  }]

  rules = [{
    legalName: ['required', values => this.validateContent('legalName', values)],
    agency: ['required', values => this.validateAgency(values)],
    currentAccount: ['required', values => this.validateBankAccount(values)],
    taxId: 'CPF_CNPJ',
    phone: 'mobile',
    email: 'email',
    bankName: ['required', values => this.validateContent('bankName', values)],
  }, {
    holderName: [values => this.validateContent('holderName', values)],
    zipCode: ['required', values => this.validateContent('zipCode', values)],
    addressState: ['required', values => this.validateContent('addressString', values)],
    city: ['required', values => this.validateContent('addressString', values)],
    neighborhood: ['required', values => this.validateContent('addressString', values)],
    addressStreet: ['required', values => this.validateContent('addressString', values)],
    addressNumber: ['required', values => this.validateContent('addressNumber', values)],
  }, {
    transferInterval: 'required',
    transferDay: [values => this.validateContent('transferDay', values)]
  }]

  maybes = {
    phone: [
      'required', values => !values.phone.length
    ],
    email: [
      'required', values => !values.email.length
    ],
    taxId: [
      'required', values => !values.taxId.length
    ]
  }

  onBankSelectionChange = (e, { name, value }, cb) => {
    const { allBanks } = this.state;
    const [selectedBank] = allBanks.filter(bank => bank.code === value);
    this.setValue(name, selectedBank.text, cb);
    this.setValue('bankCode', value, cb);
  }

  mapBanks = generalBankList => generalBankList && generalBankList.map((bank, key) => ({ key, text: bank.value, value: bank.code, code: bank.code, 'data-id': bank.code }));

  getBanks = async () => {
    let apiBanks = banks;
    try {
      apiBanks = await getBrazilianBanks();
    } catch (err) {
      console.error('Falied fetching banks from Brasil API, getting banks from local file instead. Error:', err);
    }
    return this.mapBanks(apiBanks);
  }

  componentDidMount = () => {
    if (!this.state.allBanks) {
      this.setState({ allBanks: this.mapBanks(banks) });
      this.getBanks().then(allBanks => this.setState({ allBanks }));
    }
  }

  handlePressNext = (e) => {
    const { activeIndex } = this.state;

    if (activeIndex === 0) {
      const values = pick(this.state.values, Object.keys(this.defaultValues[activeIndex]));

      this.runActionAfterValidation(
        () => {
          this.setState({ errors: {} });
          this.props.store.appends.push(
            <BankConfirmationModal
              accountData={values}
              confirmationAction={() => this.setState({ activeIndex: activeIndex + 1, errors: {} })}
            />
          );
        },
        values,
        this.rules[activeIndex],
        this.maybes
      );
    } else this.handleNext(e);
  }

  handleSubmitButton = (e) => {
    if (!this.props.edit) this.handleSubmit(e);

    Promise.resolve(this.beforeSubmit({ ...this.state.values }))
      .then((values) => {
        const { values: previousValues } = this.props;

        if (previousValues.bankName == values.bankName
          && previousValues.agency == values.agency
          && previousValues.currentAccount == values.currentAccount) {
          return this.handleSubmit(e);
        }

        return this.runActionAfterValidation(
          () => {
            this.setState({ errors: {} });
            this.props.store.appends.push(
              <BankConfirmationModal
                accountData={values}
                confirmationAction={() => this.handleSubmit(e)}
              />
            );
          },
          values,
          Array.isArray(this.rules) ? Object.assign({}, ...this.rules) : this.rules,
          this.maybes
        );
      });
  }

  afterOnSubmit = () => {
    if (this.props.store.snackbar.success) {
      this.setState({ activeIndex: this.state.activeIndex + 1 });
    }
  }

  validateContent = (name, values) => {
    if (name === 'transferDay') {
      if (this.state.values.transferInterval === 'MANUAL' || this.state.values.transferInterval === 'DAILY' || !!values) {
        return values;
      }
      throw Error(__('Cannot be empty'));
    }

    if (name === 'legalName' && values.trim().length < 3) {
      throw new Error(__('Legal name should be at least 3 characters long'));
    }

    if (name === 'legalName' && values.trim().length > 30) {
      throw new Error(__('Legal name should not be longer than 30 characters'));
    }

    if (name === 'zipCode' && values.replace(/[^\d]+/g, '').length < 8) {
      throw new Error(__('Zip code should be at least 8 digits long'));
    }

    if (name === 'addressNumber' && values < 0) {
      throw new Error(__('Invalid address number'));
    }

    if (values.trim().length === 0) {
      throw new Error(__('Cannot be empty'));
    }

    return values;
  }

  validateAgency = (agency) => {
    const agencyRegex = /(.+-.+)|([0-9]+)/; // 123A-X or 1234
    if (!agency.match(agencyRegex)) {
      throw new Error(__('Invalid agency number'));
    }

    return agency;
  };

  validateBankAccount = (account) => {
    const accountRegex = /([0-9]+-[0-9]+)/; // 123456-7 Mundipagg always wants the account with the verification digit !
    if (!account.match(accountRegex)) {
      throw new Error(__('Invalid bank account number'));
    }

    return account;
  };

  onInputChangeLimitCharacters = (e, { name, value, checked }) => {
    value = value.length > 255 ? value.slice(0, 255) : value;
    this.onInputChange(e, { name, value, checked });
  }

  onInputChangeLimitLegalName = (e, { name, value, checked }) => {
    value = value.length > 100 ? value.slice(0, 100) : value;
    this.onInputChange(e, { name, value, checked });
  }

  onError = (errors) => {
    const { rules } = this;
    const { activeIndex } = this.state;
    const errorsKeys = Object.keys(errors);

    const currentTabsWithErrors = [];
    errorsKeys.forEach(currentErrorKey =>
      rules.map((item, index) => {
        if (Object.keys(item).includes(currentErrorKey) && !currentTabsWithErrors.includes(index)) {
          currentTabsWithErrors.push(index);
        }
      })
    );

    if (!currentTabsWithErrors.includes(activeIndex) && currentTabsWithErrors.includes(activeIndex).length > 0) {
      this.setState({ activeIndex: currentTabsWithErrors[0] });
    }

    this.props.store.snackbar = { active: true, message: __('Some field has invalid values!'), success: false };
  }

  getTransferDays = () => {
    const { transferInterval } = this.state.values;
    if (transferInterval === 'MONTHLY') {
      return [...Array(28).keys()].map((day, key) => ({ text: (day + 1), value: (day + 1), key, 'data-id': (day + 1) }));
    }
    if (transferInterval === 'WEEKLY') {
      return weekdays().map((day, key) => ({ text: day.text, value: day.value, key, 'data-id': day.value }));
    }
  }

  getDisabledFieldToolTip = () => {
    return <Popup
    hoverable
    trigger={<Icon name="exclamation triangle outline" size="medium" style={{ opacity: 0.6, marginLeft: '5px' }} />}
    content={__('To edit this field, please contact our Support.')}
  /> 
  }

  isCreateOrAccountManager = () => {
    return (!this.props.edit || this.props.accountManager);
  };

  getBankInput = (values, errors) => {
    let dropdownProps = {
      name: 'bankName',
      value: values.bankCode, 
      placeholder:__('Search') + '...',
      className:'wordWrapped',
      key:'bankName',
      name:'bankName',
      noResultsMessage:__('No results were found'),
      fluid: true,
      selection: true,
      value:values.bankCode,
      options:this.state.allBanks,
      error:errors && errors.bankName,
      disabled: true
    };

    if(this.isCreateOrAccountManager()) {
      dropdownProps = {
        ...dropdownProps, 
        onChange:(e, { name, value }) => this.onBankSelectionChange(e, { name, value }),
        disabled: false,
        search: true,
      };
    }

    return <Form.Dropdown {...dropdownProps} />;
  }

  getAgencyInput = (values, errors) => {
    let inputProps = {
      name:'agency',
      value:values.agency,
      disabled: true,
    };

    if(this.isCreateOrAccountManager()) {
      inputProps = {
        ...inputProps,
        onChange:(e) => this.onInputChange(null, e.target),
        placeholder:__('9999'),
        style:{width:'260px', height:'38px'},
        error:errors && errors.agency,
        disabled: false
      };
    }

    return <Form.Input {...inputProps} />;
  }

  getAccountInput = (values, errors) => {
    let inputProps = {
      name:'currentAccount',
      value:values.currentAccount,
      disabled: true,
    };

    if(this.isCreateOrAccountManager()) {
      inputProps = {
        ...inputProps,
        onChange:(e) => this.onInputChange(null, e.target),
        placeholder:__('99999-1'),
        style:{width:'250px', height:'38px'},
        error:errors && errors.agency,
        disabled: false
      };
    }

    return <Form.Input {...inputProps} />;
  }

  renderAccountInfo = (values, errors) => (
    <div>
      <p className="bold" style={styles.label}>{__('Legal name')}</p>
      <Form.Input
        placeholder={__('Type the legal name of your institution')}
        name="legalName"
        value={values.legalName}
        onChange={this.onInputChangeLimitLegalName}
        error={errors && errors.legalName}
        autoFocus
      />
      <Form style={{ display: '-webkit-inline-box', WebkitBoxAlign: 'baseline' }}>
        <Form.Field
          name="phone"
          type="text"
          placeholder={__('Area Code + Phone')}
          defaultValue={values.phone}
          action={(errors && errors.phone) && <Popup
            trigger={<Button type="button" icon="exclamation triangle" basic color="red" />}
            content={errors && errors.phone}
          />}
          control={PhoneInput}
          onChange={value => this.onInputChange(null, { name: 'phone', value: value && value.replace(/[^0-9 ()+-]+$/, ''), checked: '' })}
          error={(errors && errors.phone)}
        />
        <Form.Input
          style={{ marginLeft: '12px', width: '250px', height: '38px' }}
          name="email"
          placeholder={__('Email')}
          value={values.email}
          icon="envelope"
          iconPosition="left"
          action={(errors && errors.email) && <Popup
            trigger={<Button type="button" icon="exclamation triangle" basic color="red" />}
            content={errors && errors.email}
          />}
          onChange={this.onInputChange}
          error={errors && errors.email}
        />
      </Form>
      <div style={{ display: 'flex', marginTop: 6 }}>
        <span style={{ fontWeight: 'bold', marginBottom: 10 }}>{__('Bank')}</span>
        {!this.isCreateOrAccountManager() ? this.getDisabledFieldToolTip() : ''}
        <div>
          {
            errors && errors.bankName &&
              <div style={{ marginBottom: 10 }}>
                <Icon data-value="error" name="exclamation circle" size="large" color="red" />
                {errors.bankName}
              </div>
          }
        </div>
      </div>
      <div>
        {this.getBankInput(values, errors)}
        <p style={{ fontSize: '12px', fontWeight: 'bold', color: '#868e96' }}>
          {__('For Bradesco bank accounts the withdrawal is free. In other cases, there is a R$3,67 fee per withdrawal')}
        </p>
      </div>
      <div style={{ display: 'inline-flex', marginTop: '24px' }}>
        <div>
          <span style={{ fontWeight: 'bold', marginBottom: 10 }}>{__('Bank agency (with digit)')}</span>
          {!this.isCreateOrAccountManager() ? this.getDisabledFieldToolTip() : ''}
          <div>
            {this.getAgencyInput(values, errors)}
          </div>
        </div>
        <div style={{ marginLeft: '12px' }}>
          <span style={{ fontWeight: 'bold', marginBottom: 10 }}>{__('Current account')}</span>
          {!this.isCreateOrAccountManager() ? this.getDisabledFieldToolTip() : ''}
          <div>
            {this.getAccountInput(values, errors)}
          </div>
        </div>
      </div>
      <p style={{ fontWeight: 'bold', marginTop: 24, marginBottom: 10 }}>
        {__('Tax ID of bank account')}
      </p>
      <div>
        {this.props.edit ?
          <Form.Input
            name="taxId"
            value={values.taxId}
            disabled
          /> : <InputMask
            mask={this.state.isCNPJ ? '99.999.999/9999-99' : '999.999.999-99'}
            beforeMaskedValueChange={(newState, oldState, userInput) => {
              const { value: oldValue } = oldState;
              const { value: newValueState, selection } = newState;
              let newValue = newValueState;

              if (userInput && oldValue && oldValue.replace(/[_/.-]/g, '').length === 11) {
                newValue = newValue.concat(userInput);
                selection.start++;
                selection.end++;
                this.setState({ isCNPJ: true });
              } else if (userInput && userInput.replace(/[_/.-]/g, '').length > 11) {
                // if the user paste a CNPJ
                newValue = userInput.substr(0, 18);
                selection.start = 18;
                selection.end = 18;
                this.setState({ isCNPJ: true });
              } else if (this.state.isCNPJ && newValue && newValue.replace(/[_/.-]/g, '').length < 12) {
                this.setState({ isCNPJ: false });
              }

              // Reinforcing value setting, in cases it is not done automatically by the input
              values.taxId = newValue;

              return { value: newValue, selection };
            }}
            value={values.taxId}
            onChange={e => this.onInputChange(null, e.target)}
          >
            {
              inputProps =>
                <Form.Input
                  name="taxId"
                  {...inputProps}
                  placeholder={this.state.isCNPJ ? '__.___.___/____-__' : '___.___.___-__'}
                  error={errors && errors.taxId}
                />
            }
          </InputMask>
        }
        {this.props.edit ?
          <p style={{ fontSize: '12px', fontWeight: 'bold', color: '#868e96' }}>{__('You can not edit the tax id field, if the information is incorrect please contact our support team.')}</p>
          : <p><span style={{ fontSize: '12px', fontWeight: 'bold' }}>{__('Attention:')}</span> <span style={{ fontSize: '12px', fontWeight: 'bold', color: '#868e96' }}>{__('The tax id must be the tax id attached to the bank account. For security reasons it is not possible to edit the tax id later, please make sure to input the correct tax id of the bank account.')}</span></p>
        }
      </div>
      <div style={{ display: 'inline-flex', marginTop: '24px', marginBottom: '24px' }}>
        <div style={{ display: 'contents' }}>
          <div>
            <ColoredCheckbox
              data-value={0}
              key={0}
              checked={values.default}
              onClick={() => {
                values.default = !values.default;
                this.setState({ values });
              }}
            />
          </div>
          <p style={{ fontWeight: 'bold', marginLeft: '8px' }}>{__('Make this bank account primary to your institution')}</p>
        </div>
      </div>
    </div>);

  renderTaxInformation = (values, errors, language) => {
    const { fetchingCep, cepError,
      missingState, missingCity, missingNeighborhood, missingStreet
    } = this.state;
    const isDocumentCNPJ = utils.isDocumentCNPJ(values.taxId);

    if (!isDocumentCNPJ && !this.rules[1].holderName.includes('required')) {
      this.rules[1].holderName = ['required', ...this.rules[1].holderName];
    } else if (isDocumentCNPJ && this.rules[1].holderName.includes('required')) {
      this.rules[1].holderName.shift();
    }
    return (
      <div>
        <p style={{ textAlign: 'center', fontSize: '18px', fontWeight: 'bold', margin: '0px', paddingTop: '10px' }}>
          {
            isDocumentCNPJ ?
              __('We need the data of the company registered with the document:') :
              __('We need the data of the person registered with the document:')
          }
        </p>
        <p style={{ textAlign: 'center', fontSize: '20px', fontWeight: 'bold', margin: '0px' }}>{values.taxId}</p>
        {!isDocumentCNPJ &&
          <div>
            <p className="bold" style={styles.label}>{__('Fullname of the document holder')}</p>
            <Form.Input
              placeholder={__('Fullname of the CPF holder')}
              name="holderName"
              value={values.holderName}
              onChange={this.onInputChangeLimitCharacters}
              error={errors && errors.holderName}
              autoFocus
            />
          </div>
        }
        <p className="bold" style={styles.label}>
          {
            isDocumentCNPJ ?
              __('Zip Code of the company') :
              __('ZIP code of residence of the document holder')
          }
        </p>
        {/* TODO: add 'dont know your CEP' button */}
        <CepInput
          fetching={fetchingCep}
          setFetching={(fetching) => { this.setState({ fetchingCep: fetching }); }}
          name="zipCode"
          onChange={e => this.onInputChange(null, e.target)}
          shouldDisableOnFetch={false}
          value={values.zipCode}
          placeholder={__('Type your zip code')}
          error={errors && errors.zipCode}
          onResult={(result) => {
            const { data, error } = result;
            if (data) {
              if (data.service) delete data.service; // avoid conflict with paymentService state

              this.setState((prevState) => {
                if (!data.state) {
                  prevState.missingState = true;
                } else {
                  prevState.missingState = false;
                }
                if (!data.city) {
                  prevState.missingCity = true;
                } else {
                  prevState.missingCity = false;
                }
                if (!data.neighborhood) {
                  prevState.missingNeighborhood = true;
                } else {
                  prevState.missingNeighborhood = false;
                }
                if (!data.street) {
                  prevState.missingStreet = true;
                } else {
                  prevState.missingStreet = false;
                }
                prevState.values = {
                  ...prevState.values,
                  addressState: data.state,
                  city: data.city,
                  neighborhood: data.neighborhood,
                  addressStreet: data.street
                };
                prevState.cepError = false;
                delete prevState.errors.zipCode;
                return prevState;
              });
            } else {
              console.error('zipCode DATA IS EMPTY !');
              this.setState((prevState) => {
                prevState.cepError = true;
                prevState.errors = { ...prevState.errors, zipCode: __('Invalid Zip Code') };
                prevState.values.addressState = '';
                prevState.values.city = '';
                prevState.values.neighborhood = '';
                prevState.values.addressStreet = '';
                return prevState;
              });
              if (error) console.error(error);
            }
          }}
        />
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ flex: 1 }}>
            <p className="bold" style={styles.label}>{__('State')}</p>
            <Form.Input
              placeholder={__('State')}
              name="addressState"
              value={values.addressState}
              onChange={this.onInputChangeLimitCharacters}
              error={errors && errors.addressState}
              //readOnly={!cepError} - allow typing when error with CEP
              readOnly={!missingState}
              transparent={!missingState}
              loading={fetchingCep}
              autoFocus
            />
          </div>
          <div style={{ flex: 1, marginLeft: '8px' }}>
            <p className="bold" style={styles.label}>{__('City')}</p>
            <Form.Input
              placeholder={__('City name')}
              name="city"
              value={values.city}
              onChange={this.onInputChangeLimitCharacters}
              error={errors && errors.city}
              autoFocus
              readOnly={!missingCity}
              transparent={!missingCity}
              loading={fetchingCep}
            />
          </div>
          <div style={{ flex: 1, marginLeft: '8px' }}>
            <p className="bold" style={styles.label}>{__('Neighborhood')}</p>
            <Form.Input
              placeholder={__('Neighborhood name')}
              name="neighborhood"
              value={values.neighborhood}
              onChange={this.onInputChangeLimitCharacters}
              error={errors && errors.neighborhood}
              autoFocus
              readOnly={!missingNeighborhood}
              transparent={!missingNeighborhood}
              loading={fetchingCep}
            />
          </div>
        </div>
        <p className="bold" style={styles.label}>{__('Street')}</p>
        <Form.Input
          placeholder={__('Street name')}
          name="addressStreet"
          value={values.addressStreet}
          onChange={e => this.onInputChange(null, e.target)}
          error={errors && errors.addressStreet}
          autoFocus
          readOnly={!missingStreet}
          transparent={!missingStreet}
          loading={fetchingCep}
        />
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ flex: 1 }}>
            <p className="bold" style={styles.label}>{__('Number')}</p>
            <Form.Input
              placeholder={__('5000')}
              name="addressNumber"
              value={values.addressNumber}
              onChange={e => this.onInputChange(null, e.target)}
              error={errors && errors.addressNumber}
              autoFocus
            />
          </div>
          <div style={{ flex: 2, marginLeft: '8px' }}>
            <p className="bold" style={styles.label}>{__('Complement')}</p>
            <Form.Input
              placeholder={__('Complement your address')}
              name="addressComplement"
              value={values.addressComplement}
              onChange={this.onInputChangeLimitCharacters}
              error={errors && errors.addressComplement}
              autoFocus
            />
          </div>
        </div>
        <p style={{ fontSize: '12px', fontWeight: 'bold', color: '#868e96', paddingTop: 15 }}>
          {isDocumentCNPJ ?
            __('The address of the company is needed to generate the invoces. This information will be only used for that purpose')
            : __('The fullname and address of the document holder is needed to generate the invoces. This information will be only used for that purpose')}
        </p>
      </div>
    );
  }

  renderAccountSettings = (values, methods, errors, language) => (<div style={{ height: 490 }}>
    <div>
      <p style={{ fontWeight: 'bold', marginBottom: 10, marginTop: 10 }}>{__('Absorb fee for the following payment methods:')}</p>
    </div>
    <div style={{ display: 'flex', marginTop: '20px', marginBottom: '35px' }}>
      {
        Object.keys(methods).map((method, i) => {
          return (
            <div style={{ display: 'flex', width: `${100 / methods.length}%` }}>
              <div>
                <ColoredCheckbox
                  data-value={i + 1}
                  key={i + 1}
                  checked={methods[method].absorb}
                  onClick={() => {
                    if (methods[method].methodType === 'CARD') values.absorbCard = !values.absorbCard;
                    else if (methods[method].methodType === 'BOLETO') values.absorbBoleto = !values.absorbBoleto;
                    else values.absorbPix = !values.absorbPix;

                    this.setState({ values });
                  }}
                />
              </div>
              <div>
                <p style={{ fontWeight: 'bold', margin: '0 0 0 8px' }}>{methods[method].methodName}</p>
                <p style={{ fontWeight: 'bold', marginLeft: '8px', fontSize: 12, color: '#868e96' }}>{methods[method].feeString}</p>
              </div>
            </div>
          );
        })
      }
    </div>
    <Form.Group>
      <div style={{ width: '50%', marginLeft: 8, paddingRight: 12 }}>
        <p style={{ fontWeight: 'bold', marginBottom: 10 }}>{__('Automatic Withdrawal')}</p>
        <Form.Dropdown
          name="transferInterval"
          key="transferInterval"
          selection
          value={values.transferInterval}
          onChange={(e, { name, value }) => {
            this.onSelectionChange(e, { name, value });
            if (value === 'WEEKLY') {
              this.setValue('transferDay', 1);
            }
          }}
          options={transferIntervals()}
          error={errors && errors.transferInterval}
          fluid
          search
        />
      </div>
      <div style={{ width: '50%', marginRight: 8, paddingLeft: 12 }}>
        <p style={{ fontWeight: 'bold', marginBottom: 10 }}>{((values.transferInterval === 'MONTHLY' && language === 'pt') ? __('Withdrawals every(monthly):') : __('Withdrawals every:')) + '*'}</p>
        <Form.Dropdown
          name="transferDay"
          key="transferDay"
          selection
          disabled={values.transferInterval === 'MANUAL' || values.transferInterval === 'DAILY'}
          value={values.transferDay}
          onChange={(e, { name, value }) => this.onSelectionChange(e, { name, value })}
          options={this.getTransferDays()}
          error={errors && errors.transferDay}
        />
      </div>
    </Form.Group>
    <div style={{ display: 'flex' }}>
      <p style={{ fontSize: '12px', fontWeight: 'bold', color: '#868e96', width: '50%', paddingLeft: 2, paddingRight: 15 }}>
        {__('The minimum balance required for an automatic withdrawal is R$1,00. For Bradesco bank accounts, the withdrawal is free. In other cases, each withdrawal has a R$3,67 fee.')}
      </p>
      <p style={{ fontSize: '12px', fontWeight: 'bold', color: '#868e96', width: '50%', paddingLeft: 15 }}>
        {__('If the chosen day is on the weekend or holiday, the withdrawal is going to happen in the next working day.')}
      </p>
    </div>
  </div>)

  renderRegisteredAccount = () => (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: 64, marginRight: 64 }}>
      <div
        style={{ textAlign: 'center', fontSize: 20, fontWeight: 'bold', margin: '16px 10px 16px 10px' }}
      >
        {__('Your bank account is being registered')}
      </div>
      <div style={{ textAlign: 'center', fontSize: 16, color: '#00000099', marginBottom: 16 }}>
        {__('We will get in touch to confirm some details, and upon approval, you will receive a confirmation message. In the meantime, learn more about how ClassPay works!')}
      </div>
      <Button
        as="a"
        href="https://ajuda.classapp.com.br/hc/pt-br/articles/360052777994-Como-enviar-uma-cobran%C3%A7a-pelo-ClassApp-"
        target="_blank"
        rel="noreferrer noopener"
        className="blue"
      >
        {__('Learn more')}
      </Button>
      <Segment basic textAlign="center">
        <Image style={{ maxHeight: 250 }} src={utils.asset('/images/register_completed_new.png', 'bucket', this.props.store.app.env)} centered />
      </Segment>
    </div>
  )

  render() {
    const { values, errors, activeIndex, fetchingCep } = this.state;
    const { language } = this.props.store.currentUser;
    const paymentMethods = getAccountFormPaymentMethods(this.props?.data?.organization?.organization?.paymentMethodList, values);

    const tabContent = [this.renderAccountInfo(values, errors), this.renderTaxInformation(values, errors, language), this.renderAccountSettings(values, paymentMethods, errors, language), this.renderRegisteredAccount()];

    const steps = (<StepsModal
      items={
        [{
          icon: null,
          title: <span style={styles.stepName}>{__('Account information')}</span>,
        },
        {
          icon: null,
          title: <span style={styles.stepName}>{__('Tax informations')}</span>,
        },
        {
          icon: null,
          title: <span style={styles.stepName}>{__('Account settings')}</span>,
        },
        {
          icon: null,
          title: <span style={styles.stepName}>{__('Register completed')}</span>,
        }]}
      activeItem={activeIndex}
      circleStyle={{ background: '#FFFFFF', border: '3px solid #e8e8e8' }}
      checkedIcon={<Icon style={styles.icon} name="check" size="small" />}
      color="#0080FF"
      connected
      size={2}
      style={{ display: 'flex', width: 'calc(100% + 32px)', right: 0, paddingLeft: 2, paddingRight: 8, margin: '0 -16px' }}
    />);

    const tabsTitle = [
      { menuItem: __('Bank'), value: 'bank', index: 0 },
      { menuItem: __('Tax informations'), value: 'tax', index: 1 },
      { menuItem: __('Settings'), value: 'settings', index: 2 },
    ];

    const tabs = (<Tab
      menu={{ secondary: true, pointing: true, color: 'blue' }}
      panes={tabsTitle}
      onTabChange={(e, data) => this.setState({ activeIndex: data.activeIndex })}
      activeIndex={activeIndex}
      style={{ marginBottom: 30 }}
    />
    );

    const shouldSendRequest = this.state.activeIndex === (tabContent.length - 2);
    return (
      <Form
        id="AccountForm"
        {...this.props}
        loading={fetchingCep}
        onSubmit={this.props.add ? this.handleCancel : this.handleSubmitButton}
        submitButton={this.props.add ? __('Finish') : __('Confirm')}
        nextSafeButton={shouldSendRequest}
        safeNextButtonText={__('Create account')}
        onPressNext={shouldSendRequest ? this.handleSubmit : this.handlePressNext}
        onPressBack={this.state.activeIndex < (tabContent.length - 1) && this.handleBack}
        onCancel={this.state.activeIndex < (tabContent.length - 1) && this.handleCancel}
        disableBackButton={this.state.activeIndex === 0}
        renderNextButton={this.state.activeIndex < (tabContent.length - 1)}
        steps={steps}
        stepsModal
        tabs={tabs}
      >
        {tabContent[activeIndex]}
      </Form>
    );
  }
}
