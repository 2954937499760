import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import _ from 'lodash';

import Controller from '../../components/Controller';
import Modal from '../../components/Modal';

import AccountForm from './Form';

import { __ } from '../../i18n';
import * as utils from '../../utils';

@inject('store')
@graphql(gql`query AccountEditQuery ($organizationId: ID!) {
  node(id: $organizationId) @connection(key: "Organization", filter: ["organizationId"]) {
    ... on Organization {
      id: dbId
      accounts {
        nodes {
          id: dbId
        }
      }
    }
  }
}
`, {
  options: ownProps => ({
    variables: {
    organizationId: ownProps.params.organization_id
    }
    })
  })
@graphql(gql`mutation updateOrganizationAccount($updateOrganizationAccountMutation: UpdateOrganizationAccountInput!) {
  updateOrganizationAccount(input: $updateOrganizationAccountMutation) {
    organizationAccount {
      dbId
    }
  }
}`, {
  options: {
  refetchQueries: ['EntityBankAccountsQuery', 'AccountEditQuery']
  }
  })
@observer
export default class AccountEdit extends Controller {
  treatValues = (values) => {
    values.legalName = values && values.legalName && values.legalName.trim();
    values.bankName = values && values.bankName && values.bankName.trim();
    values.organizationAccountId = values && values.bankName && values.bankName.substring(0, 3);
    values.agency = values && values.agency && values.agency.trim();
    values.currentAccount = values && values.currentAccount && values.currentAccount.trim();
    values.taxId = values && values.taxId && values.taxId.trim();
    values.phone = values && values.phone && values.phone.replace(/[^0-9]+/g, '');
    values.email = values && values.email && values.email.trim();

    if (values.transferInterval === 'MANUAL' || values.transferInterval === 'DAILY') {
      delete values.transferDay;
    }

    if (!values.absorbedFee) values.absorbedFee = [];

    if (values.absorbBoleto) {
      values.absorbedFee.push('BOLETO');
    }
    if (values.absorbCard) {
      values.absorbedFee.push('CARD');
    }
    if (values.absorbPix) {
      values.absorbedFee.push('PIX');
    }

    const bank = [values.organizationAccountId, values.agency, values.currentAccount];

    delete values.absorbBoleto;
    delete values.absorbCard;
    delete values.absorbPix;
    delete values.activeIndex;
    delete values.organizationAccountId;
    delete values.agency;
    delete values.currentAccount;
    delete values.errors;
    delete values.bankCode;

    return {
      ...values,
      bank: bank.join(','),
      currency: 'BRL',
      country: 'BR'
    };
  }

  request = (values) => {
    const { store } = this.props;
    const mutationValues = this.treatValues(values);

    const props = {
      id: this.props.account.id,
      ...mutationValues
    };

    return this.props.mutate({
      variables: {
        updateOrganizationAccountMutation: {
          ...props,
          entityId: store.currentEntity.id
        }
      }
    }).then(() => {
      store.snackbar = { active: true, message: __('%s was saved', values.legalName), success: true };
      store.appends.pop();
    }).catch((err) => {
      store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false, dismissAfter: 6000 };
    });
  }

  render() {
    const { account, store } = this.props;
    const { legalName, bank, taxId, phone, email, bankName,
      default: primary, transferInterval, transferDay, absorbedFee,
      holderName, zipCode, addressState, city, neighborhood, addressStreet, addressNumber,
      addressComplement } = account;

    const bankInfo = _.split(bank, ',');
    const bankCode = parseInt(bankInfo[0], 10);
    const agency = bankInfo[1];
    const currentAccount = bankInfo[2];

    return (
      <Controller
        id="AccountEdit"
        modal={{ size: 'small' }}
        edit
        title={__('Edit account')}
        form={AccountForm}
        accountManager={this.props.accountManager}
        values={{
          legalName,
          taxId,
          currentAccount,
          agency,
          bankCode,
          bankName,
          phone,
          email,
          default: primary,
          transferInterval,
          transferDay: parseInt(transferDay, 10),
          absorbBoleto: absorbedFee && absorbedFee.includes('BOLETO'),
          absorbCard: absorbedFee && absorbedFee.includes('CARD'),
          absorbPix: absorbedFee && absorbedFee.includes('PIX'),
          holderName,
          zipCode,
          addressState,
          city,
          neighborhood,
          addressStreet,
          addressNumber,
          addressComplement
        }}
        loading={this.state.loading}
        errors={this.state.errors}
        onSubmit={this.onSubmit}
        onCancel={() => store.appends.pop()}
        onClose={() => store.appends.pop()}
        {...this.props}
      />
    );
  }
}
