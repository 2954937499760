import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Controller from '../../components/Controller';

import { __ } from '../../i18n';

@inject('store')
@graphql(gql`mutation deleteOrganizationAccount($deleteOrganizationAccountMutation: DeleteOrganizationAccountInput!) {
  deleteOrganizationAccount(input: $deleteOrganizationAccountMutation) {
    organizationAccount {
      dbId
      organization {
        fullname
      }
    }
  }
}`, {
  options: {
  refetchQueries: ['EntityBankAccountsQuery']
  }
  })
@observer
export default class AccountDelete extends Controller {
  request = () => this.props.mutate({
    variables: {
      deleteOrganizationAccountMutation: {
        id: this.props.account && this.props.account.id,
        entityId: this.props.store.currentEntity.id,
      }
    }
  }).then(() => {
    this.props.store.snackbar = { active: true, message: __('Bank account was deleted'), success: true };
    this.props.store.appends.pop();
  });

  render() {
    return (
      <Controller
        id="AccountDelete"
        delete
        object={__('account')}
        onSubmit={this.onSubmit}
        onCancel={() => this.props.store.appends.pop()}
        {...this.props}
      />
    );
  }
}